
import { Component, Vue, Watch } from "vue-property-decorator";
import FacilityModule from "@/store/facility";
import AuthModule from "@/store/auth";
import Cognito from "@/plugins/cognito";
import Button from "@/components/Button.vue";
import FacilityService from "@/services/facilities";
import { AnnouncementData } from "@/@types/management";
import moment from "moment";

@Component({
  components: {
    Button,
  },
})
export default class Header extends Vue {
  private get me() {
    return AuthModule.me;
  }
  private routeName = this.$route.name;

  private get hospitalName() {
    return FacilityModule.hospitalName;
  }

  private get unreadAnnouncementCount() {
    //100件以上の場合は99+にする
    if (this.unreadAnnouncementList.length >= 100) {
      return "99+";
    }
    return this.unreadAnnouncementList.length;
  }

  // 未読のお知らせリスト
  private unreadAnnouncementList: AnnouncementData[] = [];

  private async getAnnouncementList() {
    const res = await FacilityService.getAnnouncements({
      cognito_id: this.me?.id,
      read_status: "unread",
      facility_id: FacilityModule.hospitalId,
    });
    this.unreadAnnouncementList = res.data.data;
    // 公開開始日以前のものと公開終了日以降のものを除外
    this.unreadAnnouncementList = this.unreadAnnouncementList.filter((announcement) => {
      const start = moment(announcement.publish_start_at);
      const end = moment(announcement.publish_end_at);
      if (!announcement.publish_end_at) return start.isBefore(moment());
      return start.isBefore(moment()) && end.isAfter(moment());
    });
  }

  private mounted() {
    this.getAnnouncementList();
  }

  private get checkNoticeRoute() {
    if (this.routeName === "NoticeSetting" || this.routeName === "NoticeSettingEdit") {
      return true;
    }
    return false;
  }

  private get checkHospitalDetailRoute(): boolean {
    if (this.routeName === "HospitalDetail" || this.routeName === "HospitalEdit") {
      return true;
    }
    return false;
  }

  private signOut() {
    Cognito.signOut();
    this.$router.push({ name: "Login" });
  }

  private toHospitalSelect() {
    this.$router.push({ name: "HospitalSelect" });
  }
  private toChangePassword() {
    const routeName = this.$route.name;
    AuthModule.changePassRoot(routeName ?? "");
    this.$router.push({ name: "ChangePassword" });
  }

  @Watch("$route", { immediate: true, deep: true })
  private changeRoute() {
    if (this.$route.name) this.routeName = this.$route.name;
  }
}
